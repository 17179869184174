

















import _ from "lodash"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component({
  name: "humanized-date",
})
export default class HumanizedDate extends Vue {
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: String, default: "now" }) readonly from!: string
  @Prop({ type: String, default: "bottom", validator: value => _.includes(["top", "bottom", "left", "right"], value) })
  readonly tooltipPosition!: string
  @Prop({ type: String, default: "MMMM Do YYYY hh:mm:ss a" }) readonly tooltipFormat!: string
}
