var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workflow-vertical-stepper"},[(_vm.loading)?_c('content-loader',{attrs:{"height":600,"width":1200,"speed":2,"primaryColor":"#e0e0e0","secondaryColor":"#ebebeb"}},[_c('circle',{attrs:{"cx":"39","cy":"32","r":"21"}}),_c('rect',{attrs:{"x":"75","y":"22","rx":"0","ry":"0","width":"200","height":"22"}}),_c('rect',{attrs:{"x":"40","y":"68","rx":"0","ry":"0","width":"2","height":"34"}}),_c('circle',{attrs:{"cx":"39","cy":"135","r":"21"}}),_c('rect',{attrs:{"x":"75","y":"125","rx":"0","ry":"0","width":"100","height":"22"}}),_c('rect',{attrs:{"x":"40","y":"170","rx":"0","ry":"0","width":"2","height":"160"}}),_c('circle',{attrs:{"cx":"39","cy":"365","r":"21"}}),_c('rect',{attrs:{"x":"75","y":"355","rx":"0","ry":"0","width":"160","height":"22"}}),_c('circle',{attrs:{"cx":"40","cy":"200","r":"8"}}),_c('rect',{attrs:{"x":"75","y":"205","rx":"0","ry":"0","width":"180","height":"8"}}),_c('rect',{attrs:{"x":"75","y":"265","rx":"0","ry":"0","width":"150","height":"8"}}),_c('circle',{attrs:{"cx":"40","cy":"280","r":"8"}}),_c('rect',{attrs:{"x":"75","y":"185","rx":"0","ry":"0","width":"150","height":"8"}}),_c('rect',{attrs:{"x":"75","y":"285","rx":"0","ry":"0","width":"100","height":"8"}}),_c('rect',{attrs:{"x":"40","y":"405","rx":"0","ry":"0","width":"2","height":"34"}})]):_vm._t("stepper",[_vm._l((_vm.workflowSteps),function(step,stepIndex){return [_vm._t("step",[_c('stepper-step',{key:("step-" + (stepIndex + 1)),attrs:{"toggle":"","step":stepIndex + 1,"title":step.title,"subtitle":step.subtitle,"color":step.isCompleted ? 'metry-color__success-700--background' : 'metry-color__primary-900--background',"icon":step.isCompleted ? 'mdi-check' : null,"visible":step.isCurrent,"disabled":!step.isCurrent && !step.isCompleted,"data-test":"step-" + (stepIndex + 1) + "-" +
              (step.isCompleted ? 'complete' : 'incomplete') +
              "-" +
              (step.isCurrent ? 'current' : 'notcurrent')},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
              var title = ref.title;
return [_vm._t("step-title",[_vm._v(_vm._s(title))],{"title":title})]}}],null,true)},[_vm._l((step.substeps),function(substep,substepIndex){return [_vm._t("substep",[_c('stepper-sub-step',{key:("step-" + (stepIndex + 1) + "-" + (substepIndex + 1)),attrs:{"active":substep.isCurrent,"visible":substep.isCurrent,"disabled":!substep.isCurrent && !substep.isCompleted,"title":substep.title,"subtitle":substep.subtitle,"data-test":"step-" + (stepIndex + 1) + "-" + (substepIndex + 1) + "-" +
                    (substep.isCompleted ? 'complete' : 'incomplete') +
                    "-" +
                    (substep.isCurrent ? 'current' : 'notcurrent')},scopedSlots:_vm._u([{key:"header-actions-prefix",fn:function(){return [_vm._t("substep-actions",null,{"substep":substep})]},proxy:true}],null,true)},[(substep.description)?_c('div',{staticClass:"metry-text__body-2 metry-color__neutral-700"},[_vm._t("substep-description",[_vm._v(_vm._s(substep.description))],{"substep":substep})],2):_vm._e()])],{"substep":substep})]})],2)],{"step":step})]})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }