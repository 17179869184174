import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component
export default class BaseStepper extends Vue {
  contentVisible: boolean = false
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, required: false }) readonly subtitle!: string
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly toggle!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly visible!: boolean

  @Watch("visible")
  visibleWatcher(newValue: boolean) {
    this.contentVisible = newValue
  }

  toggleContent() {
    if (this.toggle) {
      this.contentVisible = !this.contentVisible
    }
  }

  async mounted() {
    this.contentVisible = this.visible
  }
}
