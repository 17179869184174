










































import { Component, Prop } from "vue-property-decorator"
import BaseStepper from "./BaseStepper"

@Component({
  name: "stepper-step",
})
export default class StepperStep extends BaseStepper {
  @Prop({ type: Number, required: true }) readonly step!: number
  @Prop({ type: String, required: false }) readonly color!: string
  @Prop({ type: String, required: false }) readonly icon!: string
  @Prop({ type: Number, required: false, default: 24 }) readonly size!: number

  get avatarColor() {
    if (this.disabled) {
      return "metry-color__neutral-400--background"
    }
    return this.color
  }

  get titleClass() {
    let cls = "pl-2 metry-text__headline-6"
    if (this.disabled) {
      cls += " metry-color__neutral-600"
    }
    return cls
  }

  get contentChevron() {
    return this.contentVisible ? "mdi-chevron-up" : "mdi-chevron-down"
  }
}
