

















































































import _ from "lodash"
import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import { ContentLoader } from "vue-content-loader"
import StepperStep from "@/components/vertical-stepper/StepperStep.vue"
import StepperSubStep from "@/components/vertical-stepper/StepperSubStep.vue"
import { cx } from "@/types"
import AppService from "@/services/app"

@Component({
  name: "workflow-vertical-stepper",
  components: {
    StepperStep,
    StepperSubStep,
    ContentLoader,
  },
})
export default class WorkflowVerticalStepper extends Vue {
  private readonly appService = new AppService()
  loading: boolean = true
  workflows: Array<cx.App.Workflow> = []

  @Prop({ type: String, required: true }) readonly workflowGroup!: string
  @Prop({ type: Number, required: true }) readonly statusId!: number

  get workflowSteps() {
    let steps: Array<cx.App.IStep> = []
    let currentStatus = null
    let currentWorkflow = null
    if (this.workflows.length !== 0) {
      let lastWorkflow: cx.App.Workflow | null = _.last(this.workflows) || null
      if (!lastWorkflow) throw "lastWorkflow is null/undefined"
      let lastStatus: cx.App.Status | null = _.last(lastWorkflow.statuses) || null
      if (!lastStatus) throw "lastStatus is null/undefined"

      let currentStatusState = currentStatus
      for (let i = 0; i < this.workflows.length; i++) {
        let workflow: cx.App.Workflow = this.workflows[i]
        let substeps: Array<cx.App.ISubstep> = []
        for (let j = 0; j < workflow.statuses.length; j++) {
          let status = workflow.statuses[j]
          // skip inactive statuses
          if (!status._active) {
            continue
          }
          let isCurrent = status.id === this.statusId
          let isCompleted = _.isNil(currentStatus) && !isCurrent
          substeps.push({
            id: status.id,
            order: status.order,
            title: status.name,
            subtitle: `Assigned to ${status.assignedTo}`,
            description: status.description,
            status: _.clone(status),
            isCompleted,
            isCurrent,
          })
          if (isCurrent) {
            currentStatus = status
          }
        }
        if (_.isNil(currentWorkflow) && (_.isNil(currentStatusState) && !_.isNil(currentStatus))) {
          currentWorkflow = workflow
        }

        let lastSubstep: cx.App.ISubstep | null = _.last(substeps) || null
        if (!lastSubstep) throw "lastSubstep is null/undefined"

        let isWorkflowCurrent = !_.isNil(currentWorkflow) && currentWorkflow.id === workflow.id
        let isWorkflowComplete =
          _.isNil(currentWorkflow) ||
          (lastSubstep.id === lastStatus.id && (lastSubstep.isCompleted || lastSubstep.isCurrent))
        steps.push({
          id: workflow.id,
          order: workflow.order,
          title: workflow.name,
          subtitle: workflow.description,
          isCompleted: isWorkflowComplete,
          isCurrent: isWorkflowCurrent,
          status: _.clone(workflow),
          substeps,
        })
      }
    }
    return steps
  }

  async mounted() {
    this.loading = true
    this.workflows = await this.appService.getWorkflowGroup(this.workflowGroup)
    this.loading = false
  }
}
