






















































import _ from "lodash"
import { Component, Vue, Prop } from "vue-property-decorator"
import { ContentLoader } from "vue-content-loader"
import CommentEditor from "@/components/CommentEditor.vue"
import UserAvatar from "@/components/UserAvatar.vue"

@Component({
  name: "comment-item",
  components: {
    UserAvatar,
    ContentLoader,
    CommentEditor,
  },
})
export default class CommentItem extends Vue {
  @Prop({ type: String, required: true }) readonly name!: string
  @Prop({ type: String, required: true }) readonly content!: string
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: Boolean, default: false }) readonly threaded!: boolean
  @Prop({ type: Boolean, default: false }) readonly tile!: boolean
  @Prop({ type: Number, default: 34 }) readonly size!: number

  editing: boolean = false
  icon: string = "mdi-account"

  get readableDate(): string {
    let now = new Date()
    let momentDate = this.$moment(this.date)
    if (momentDate.isSame(now, "day")) {
      return momentDate.fromNow()
    } else if (momentDate.isSame(now, "week")) {
      return momentDate.calendar()
    } else {
      return momentDate.format("lll")
    }
  }
}
