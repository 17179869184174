

































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import isUrl from "is-url"
import sanitizeHtml from "sanitize-html"
// eslint-disable-next-line no-unused-vars
import Quill, { RangeStatic } from "quill"
import SupportedIcons from "@/assets/supportedIcons.json"
import Delta from "quill-delta"

@Component({
  name: "comment-editor",
})
export default class CommentEdit extends Vue {
  transmitOptions: { [key: string]: any } | null = null
  loading: boolean = false
  focused: boolean = false
  content: string | null = null
  attachments: Array<any> = []
  linkSelection: RangeStatic | null = null
  linkMenu: boolean = false
  linkValid: boolean = false
  linkText: string | null = null
  linkRules: { [key: string]: any } = {
    valid: (v: string) => isUrl(v) || "Please enter a valid URL",
  }
  selection: RangeStatic | null = null
  selectionText: string | null = null
  selectionBounds: { [key: string]: any } | null = null
  selectionFormat: Array<string> = []
  selectionAlignment: string = "left"
  selectionLink: boolean | null = null
  readonly fileTypeIcons: { [key: string]: string } = SupportedIcons
  $refs!: {
    editor: any
    transmit: any
    linkInput: any
  }
  @Prop({ type: Function, required: true }) readonly onCreate!: Function
  @Prop({ type: Boolean, required: false, default: false }) readonly dark!: boolean
  @Prop({ type: String, required: false, default: "Write a comment..." }) readonly placeholder!: string
  @Prop({ type: Boolean, required: false, default: false }) readonly autofocus!: boolean
  @Prop({ type: String, required: false, default: "Comment" }) readonly buttonText!: string
  @Prop({ type: String, required: false, default: "Posting..." }) readonly loadingButtonText!: string

  created() {
    this.transmitOptions = {
      draggable: false,
      clickable: false,
      url: this.$route.fullPath,
      autoProcessQueue: false,
      autoQueue: false,
      accept: this.handleAttachment,
    }
  }

  get editor() {
    return this.$refs.editor.quill
  }

  get editorOptions() {
    return {
      modules: {
        toolbar: false,
      },
    }
  }

  get editorKeyListeners() {
    return [
      {
        config: {
          key: 66,
          ctrlKey: true,
        },
        handler: this.buildFormatHandler("bold"),
      },
      {
        config: {
          key: 73,
          ctrlKey: true,
        },
        handler: this.buildFormatHandler("italic"),
      },
      {
        config: {
          key: 85,
          ctrlKey: true,
        },
        handler: this.buildFormatHandler("underline"),
      },
      {
        config: {
          key: 75,
          ctrlKey: true,
        },
        handler: this.showLinkMenu,
      },
    ]
  }

  get toolbarDisabled() {
    return this.loading || !this.focused
  }

  get tooltipPosition() {
    let position = { x: 0, y: 0 }
    if (!_.isNil(this.selectionBounds)) {
      let clientRect = this.$refs.editor.$el.getBoundingClientRect()
      // NOTE: +28 due to font size being 24px with an additional 4px for padding
      // TODO: might base tooltip offset on current selection font size
      position = {
        x: clientRect.left + this.selectionBounds.left,
        y: clientRect.top + this.selectionBounds.top + 28,
      }
    }
    return position
  }

  get tooltipHint() {
    let tooltip = "Enter a URL"
    if (!_.isNil(this.linkText)) {
      tooltip = `Create link <a href="${this.linkText}" target="_blank">${this.selectionText}</a>`
    } else {
      if (!_.isNil(this.selectionText)) {
        tooltip += ` for "${this.selectionText}"`
      }
    }
    return tooltip
  }

  get contentSelected() {
    return (
      !_.isNil(this.selection) &&
      !_.isEmpty(_.replace(this.editor.getText(this.selection.index, this.selection.length), "\n", ""))
    )
  }

  @Watch("placeholder")
  placeholderWatcher(value: string) {
    this.setPlaceholder(value)
  }

  async createComment() {
    this.editor.enable(false)
    this.loading = true
    try {
      await this.onCreate({
        content: this.content,
        attachments: this.attachments,
      })
      this.editor.setContents([])
    } finally {
      this.loading = false
      this.editor.enable(true)
    }
  }

  buildFormatHandler(option: string) {
    return (range: RangeStatic, context: any) => {
      if (_.includes(this.selectionFormat, option)) {
        _.pull(this.selectionFormat, option)
        this.selectionFormat = _.clone(this.selectionFormat)
      } else {
        this.selectionFormat.push(option)
      }
      this.editor.format(option, !context.format[option], "user")
    }
  }

  handleAttachment(file: any) {
    // NOTE: this is how we disable attachments
    // this.attachments.push(file)
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1)
  }

  setPlaceholder(value: string) {
    this.editor.root.setAttribute("data-placeholder", value)
  }

  updateSelection() {
    this.selection = this.editor.getSelection()
    // check that selection is a Range object
    let isRange = _.isObject(this.selection) && _.has(this.selection, "index") && _.has(this.selection, "length")
    if (isRange) {
      let range: RangeStatic | null = _.clone(this.selection)
      if (!range) throw "range is undefined"

      let format = this.editor.getFormat(range.index, range.length)
      let isNewline = _.isObject(_.find(_.get(this.editor.getContents(range.index - 1, 1), "ops"), { insert: "\n" }))

      this.selectionBounds = this.editor.getBounds(range.index, range.length)
      this.selectionText = this.editor.getText(range.index, range.length)

      if (!isNewline) {
        // only update toolbar selections when not updated from a new line
        this.selectionFormat = _.keys(_.pick(format, ["bold", "italic", "underline", "strike"]))
        this.selectionAlignment = _.get(format, "align", "left")
        this.selectionLink = _.get(format, "link")
      }
    }
  }

  handleLink() {
    if (!_.isNil(this.selectionLink)) {
      this.onRemoveLink()
    } else {
      this.showLinkMenu()
    }
  }

  showLinkMenu() {
    if (!_.isNil(this.selection)) {
      this.linkMenu = true
      // NOTE: focus timeout is because animation of link menu needs to complete first
      setTimeout(() => {
        this.$refs.linkInput.focus()
      }, 200)
    }
  }

  resetLinkMenu() {
    this.linkText = null
    this.linkValid = true
    this.linkMenu = false
    this.editor.focus()
  }

  onCreateLink() {
    if (!_.isNil(this.linkSelection)) {
      this.editor.formatText(this.linkSelection.index, this.linkSelection.length, "link", this.linkText)
      this.resetLinkMenu()
    }
  }

  onRemoveLink() {
    if (this.contentSelected) {
      this.editor.format("link", false)
    }
  }

  onCreateAttachment() {
    this.$refs.transmit.triggerBrowseFiles()
  }

  onCreateBulletedList() {
    if (this.contentSelected && this.selection) {
      this.editor.formatText(this.selection.index, this.selection.length, "list", "bullet")
    } else {
      this.editor.format("list", "bullet")
    }
  }

  onCreateOrderedList() {
    if (this.contentSelected && this.selection) {
      this.editor.formatText(this.selection.index, this.selection.length, "list", "ordered")
    } else {
      this.editor.format("list", "ordered")
    }
  }

  onFormatChange() {
    if (!this.selection) return
    let currentFormat = this.contentSelected
      ? _.keys(this.editor.getFormat(this.selection.index, this.selection.length))
      : _.keys(this.editor.getFormat())
    let selectionFormat = _.clone(this.selectionFormat)
    // NOTE: add `align` to avoid format changes removing alignment preferences
    selectionFormat.push("align")

    let toAdd = _.difference(selectionFormat, currentFormat)
    let toRemove = _.difference(currentFormat, selectionFormat)

    _.forEach(toRemove, option => {
      if (this.contentSelected) {
        this.editor.formatText(this.selection!.index, this.selection!.length, option, false)
      } else {
        this.editor.format(option, false)
      }
    })
    _.forEach(toAdd, option => {
      if (this.contentSelected) {
        this.editor.formatText(this.selection!.index, this.selection!.length, option, true)
      } else {
        this.editor.format(option, true)
      }
    })
  }

  onAlignmentChange() {
    if (this.contentSelected && this.selection) {
      this.editor.formatLine(this.selection.index, this.selection.length, "align", this.selectionAlignment)
    } else {
      this.editor.format("align", this.selectionAlignment)
    }
  }

  mounted() {
    this.setPlaceholder(this.placeholder)
    if (this.autofocus) {
      this.editor.focus()
    }

    // setup editor shortcuts, options, and event bindings
    this.$nextTick(() => {
      // register custom keyboard bindings
      let defaultEditorKeys = _.map(_.keys(this.editor.keyboard.bindings), _.parseInt)
      _.forEach(this.editorKeyListeners, keyListener => {
        if (_.includes(defaultEditorKeys, keyListener.config.key)) {
          this.editor.keyboard.bindings[keyListener.config.key].unshift(
            _.merge(keyListener.config, { handler: keyListener.handler })
          )
        } else {
          this.editor.keyboard.addBinding(keyListener.config, keyListener.handler)
        }
      })

      // event translations
      this.editor.on("text-change", (delta: Delta, oldDelta: Delta, source: string) => {
        this.$emit("text-change", delta, oldDelta, source)
        this.$emit("change", this.content)
      })

      this.editor.on("selection-change", (range: RangeStatic, oldRange: RangeStatic, source: string) => {
        this.$emit("selection-change", range, oldRange, source)
        if (_.has(range, "index") && _.has(range, "length")) {
          this.linkSelection = range
          this.$emit("select", this.editor.getText(range.index, range.length))
        }
      })

      this.editor.on("editor-change", (eventName: string, ...args: any) => {
        this.updateSelection()
        this.$emit("editor-change", eventName, ...args)
      })

      // handle initial text content insert
      if (_.isString(this.$slots.default)) {
        let content = _.clone(this.$slots.default)
        this.editor.clipboard.dangerouslyPasteHTML(sanitizeHtml(content))
      }
    })
  }
}
