



























import _ from "lodash"
import { Component, Vue, Prop } from "vue-property-decorator"
import CommentTree from "@/components/CommentTree.vue"
import CommentEditor from "@/components/CommentEditor.vue"
import CommentItem from "@/components/CommentItem.vue"

@Component({
  name: "comment-feed",
  components: {
    CommentTree,
    CommentItem,
    CommentEditor,
  },
})
export default class CommentFeed extends Vue {
  @Prop({ type: Function, default: null, required: false }) readonly onCreate!: (note: {
    content: string
    attachments: Array<object>
  }) => void
  @Prop({ type: Function, default: null }) readonly loadMore!: (limit: number, offset: number) => Array<object>
  @Prop({ type: Function, default: null }) readonly loadTotal!: () => number
  @Prop({ type: String, default: "Comments" }) readonly title!: string
  @Prop({ type: Number, default: 5 }) readonly fetchLimit!: number
  @Prop({ type: Boolean, default: false }) readonly tile!: boolean
  @Prop({ type: Number, default: 34 }) readonly size!: number
  @Prop({ type: String, default: "mdi-person" }) readonly icon!: string
  @Prop({
    type: String,
    default: "characters",
    validator: (value: string) => ["characters", "icon"].indexOf(value) > -1,
  })
  readonly fallback!: string

  loading: boolean = false
  total: number = 0
  comments: Array<object> = []

  get unloadedComments(): number {
    return this.total - _.size(this.comments)
  }

  async fetchTotal() {
    this.total = await this.loadTotal()
  }

  async fetchComments() {
    if (this.unloadedComments > 0) {
      this.loading = true
      this.comments = _.concat(this.comments, await this.loadMore(this.fetchLimit, _.size(this.comments)))
      this.loading = false
    }
  }

  async mounted() {
    this.fetchTotal()
    this.fetchComments()
  }
}
