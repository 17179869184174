























































import { Component, Prop } from "vue-property-decorator"
import BaseStepper from "./BaseStepper"

@Component({
  name: "stepper-sub-step",
})
export default class StepperSubStep extends BaseStepper {
  @Prop({ type: Boolean, required: false, default: false }) readonly active!: boolean

  get stepClass() {
    if (this.disabled) {
      return "stepper-sub-step--disabled"
    }
    if (this.active) {
      return "stepper-sub-step--active"
    }
    return ""
  }

  get dotClass() {
    if (this.disabled) {
      return "step-dot--disabled"
    }
    return ""
  }

  get contentChevron() {
    return this.contentVisible ? "mdi-chevron-up" : "mdi-chevron-down"
  }
}
