






























import { Component, Vue, Prop } from "vue-property-decorator"
import CommentItem from "@/components/CommentItem.vue"

@Component({ name: "comment-tree", components: { CommentItem } })
export default class CommentTree extends Vue {
  @Prop({ type: Array, required: true }) readonly comments!: Array<object>
  @Prop({ type: Boolean, default: false }) readonly tile!: boolean
  @Prop({ type: Number, default: 34 }) readonly size!: number
  @Prop({
    type: String,
    default: "characters",
    validator: (value: string) => ["characters", "icon"].indexOf(value) > -1,
  })
  readonly fallback!: string
  @Prop({ type: String, default: "person" }) icon!: string
}
