






















































































































































































































































import _ from "lodash"
import { Getter } from "vuex-class"
import { Component, Vue, Watch } from "vue-property-decorator"
import { Auth0UserProfile } from "auth0-js"

import CXService from "@/services/cx"
import EngagementService from "@/services/engagement"
import AppService from "@/services/app"
import { cx } from "@/types"
import HumanizedDate from "@/components/HumanizedDate.vue"
import Trunquee from "@/components/Trunquee.vue"
import CommentFeed from "@/components/CommentFeed.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import EmptyState from "@/components/EmptyState.vue"
import ScrollFab from "@/components/ScrollFab.vue"
import WorkflowVerticalStepper from "@/components/WorkflowVerticalStepper.vue"
import UserAvatar from "@/components/UserAvatar.vue"

@Component({
  name: "statustracker-details",
  components: {
    Trunquee,
    CommentFeed,
    HumanizedDate,
    LoadingSpinner,
    ScrollFab,
    EmptyState,
    UserAvatar,
    WorkflowVerticalStepper,
  },
})
export default class StatusTrackerDetails extends Vue {
  private readonly cxService = new CXService()
  private readonly engagementService = new EngagementService()
  private readonly appService = new AppService()
  loading: boolean = true
  unknown: boolean = false
  uploadingAttachment: boolean = false
  project: cx.StatusTracker.Project | null = null
  workflows: Array<cx.App.Workflow> = []
  history: Array<cx.StatusTracker.ProjectHistory> = []
  statusIds: Array<number> = []
  statusDates: { [k: number]: { start: string | null; end: string | null } } = {}
  defaultContactName: string = "No Contact Assigned"
  defaultSensusContactLabel: string = "Sensus Professional Business Analyst"
  $refs!: {
    comments: CommentFeed
  }

  get referenceNumber(): string {
    return this.$route.params.referenceNumber
  }

  get contacts(): Array<cx.StatusTracker.ProjectContact> {
    if (!_.isNil(this.project)) {
      let contacts: Array<cx.StatusTracker.ProjectContact> = []
      if (!_.isNil(this.project.user)) {
        let sensusContact = this.project.user
        contacts.push({
          name: sensusContact.name || this.defaultContactName,
          label: sensusContact.title || this.defaultSensusContactLabel,
          phone: sensusContact.phone || "",
          email: sensusContact.email || "",
          picture: sensusContact.avatarUrl || "",
        })
      }

      contacts = _.concat(contacts, [
        {
          name: this.project.contacts.distributor.name || this.defaultContactName,
          label: this.project.customer || "Distributor",
          phone: this.project.contacts.distributor.phone || "",
          email: this.project.contacts.distributor.email || "",
        },
        {
          name: this.project.contacts.customer.name || this.defaultContactName,
          label: this.project.account || "Customer",
          phone: this.project.contacts.customer.phone || "",
          email: this.project.contacts.customer.email || "",
        },
        {
          name: this.project.contacts.cis.name || this.defaultContactName,
          label: this.project.vendor || "Billing Vendor",
          phone: this.project.contacts.cis.phone || "",
          email: this.project.contacts.cis.email || "",
        },
      ])
      return _.compact(
        _.filter(contacts, (contact: cx.StatusTracker.ProjectContact) => {
          return _.isString(contact.name)
        })
      )
    }
    return []
  }

  get details(): Array<cx.StatusTracker.ProjectDetail> {
    if (!_.isNil(this.project)) {
      let details = []
      let applications = this.project.applications
      let comodityTypes = this.project.comodityTypes
      if (_.size(applications) > 0) {
        details.push({
          name: _.join(applications, ", "),
          label: "Applications",
        })
      }
      if (_.size(comodityTypes) > 0) {
        details.push({
          name: _.join(comodityTypes, ", "),
          label: "Comodity Types",
        })
      }
      return details
    }
    return []
  }

  get optionalTasks(): Array<cx.StatusTracker.ProjectOptionalTask> {
    let availableTasks = [
      {
        name: "Integration Overview Requested",
        description: "Utility has requested an integration overview.",
        field: "overviewRequested",
      },
      {
        name: "Training Requested",
        description: "Utility has purchased Sensus Analytics training.",
        field: "trainingRequested",
      },
    ]
    if (!_.isNil(this.project)) {
      let tasks: Array<cx.StatusTracker.ProjectOptionalTask> = []
      _.forEach(availableTasks, (task: cx.StatusTracker.ProjectOptionalTask) => {
        if (_.get(this.project, task.field, false)) {
          tasks.push(task)
        }
      })
      return tasks
    }
    return []
  }

  get statusId(): number | null {
    if (!_.isNil(this.project)) {
      if (_.includes(this.statusIds, this.project.status.id)) {
        return this.project.status.id
      } else if (_.isArray(this.statusIds) && _.isArray(this.history) && _.size(this.history) > 0) {
        let largestIndex = null
        for (let i = 0; i < this.history.length; i++) {
          let statusIndex = _.indexOf(this.statusIds, this.history[i].new)
          if (_.isNil(largestIndex) || statusIndex >= largestIndex) {
            largestIndex = statusIndex
          }
        }
        if (_.isNumber(largestIndex)) {
          return this.statusIds[largestIndex]
        }
      }
    }
    return null
  }

  get activeStatuses(): Array<cx.App.Status> | null {
    if (!_.isNil(this.workflows) && _.size(this.workflows) > 0) {
      let activeStatuses: Array<cx.App.Status> = []
      _.forEach(this.workflows, (workflow: cx.App.Workflow) => {
        activeStatuses = _.concat(
          activeStatuses,
          _.filter(workflow.statuses, status => status._active)
        )
      })
      return activeStatuses
    }
    return null
  }

  get currentStatus(): cx.App.Status | null {
    if (!_.isNil(this.activeStatuses) && _.size(this.activeStatuses) > 0) {
      return _.find(this.activeStatuses, (status: cx.App.Status) => status.id === this.statusId) || null
    }
    return null
  }

  get currentStatusIndex(): number {
    if (!_.isNil(this.currentStatus)) {
      return _.indexOf(this.activeStatuses, this.currentStatus) + 1
    }
    return 0
  }

  get progress(): number {
    if (!_.isNil(this.currentStatusIndex)) {
      return this.currentStatusIndex / _.size(this.activeStatuses)
    }
    return 0
  }

  @Getter("user/getUser")
  user!: Auth0UserProfile

  async getProjectNotes(limit: number, offset: number): Promise<Array<cx.StatusTracker.ProjectNote>> {
    return this.engagementService.getProjectNotes(this.referenceNumber, limit, offset)
  }

  async getProjectNotesTotal(): Promise<number> {
    return this.engagementService.getProjectNotesTotal(this.referenceNumber)
  }

  uploadAttachment(event: any) {
    const file: File = event.target.files[0]

    if (file.size > 26214400) {
      this.$events.emit("show-snackbar", {
        text: `File exceeds maximum size limit of 25MB`,
        color: "error",
      })
      return
    }

    this.uploadingAttachment = true
    this.cxService
      .createEngagementAttachment(this.project!, this.user, file)
      .then(result => {
        this.$events.emit("show-snackbar", {
          text: `Successfully uploaded ${file.name}`,
          color: "success",
        })
      })
      .catch(error => {
        console.error(error)
        this.$raven.captureException(error)
        this.$events.emit("show-snackbar", {
          text: `Failed to upload ${file.name}`,
          color: "error",
        })
      })
      .finally(() => {
        this.uploadingAttachment = false
      })
  }

  async createNote(note: { content: string; attachments: Array<object> }) {
    let content = note.content
    let userSalesforceId = _.last(_.split(this.user.user_id, "|"))

    try {
      if (!this.project || !this.project.salesforceId) throw "salesforceId is undefined or null!"
      let createdNoteId = await this.cxService.createProjectNote(
        content,
        userSalesforceId!,
        this.project!.id,
        this.project.salesforceId
      )
      this.$refs.comments.comments.unshift({
        id: createdNoteId,
        content,
        date: new Date().toISOString(),
        author: {
          name: this.user.name,
          picture: this.user.picture,
        },
      })
    } catch (error) {
      this.$events.emit("show-snackbar", {
        text: `Failed to create comment`,
        color: "error",
        top: true,
        right: true,
        duration: 500,
      })
      throw error
    }
  }

  async getStatusStartDate(
    history: Array<cx.StatusTracker.ProjectHistory>,
    statusId: number,
    format: string = "YYYY-MM-DD"
  ): Promise<string | null> {
    let startDate = _.get(
      _.first(
        _.get(
          _.mapValues(_.groupBy(history, "new"), group => _.orderBy(group, "_created")),
          statusId,
          []
        )
      ),
      "_created",
      null
    )
    return _.isString(startDate) ? this.$moment(startDate).format(format) : null
  }

  async getStatusEndDate(
    history: Array<cx.StatusTracker.ProjectHistory>,
    statusId: number,
    format: string = "YYYY-MM-DD"
  ): Promise<string | null> {
    let endDate = _.get(
      _.last(
        _.get(
          _.mapValues(_.groupBy(history, "old"), group => _.orderBy(group, "_created")),
          statusId,
          []
        )
      ),
      "_created",
      null
    )
    return _.isString(endDate) ? this.$moment(endDate).format(format) : null
  }

  async mounted() {
    this.loading = true
    try {
      this.project = await this.engagementService.getProject(this.referenceNumber)
      let historyPromise = this.engagementService.getProjectHistory(this.project.id)
      this.workflows = await this.appService.getWorkflowGroup("engagement-sa-standard")
      this.history = await historyPromise
      this.statusIds = await this.engagementService.getProjectStatusIds()

      let statusDates: { [k: number]: { start: string | null; end: string | null } } = {}
      let lastStatus = _.last(_.last(this.workflows)!.statuses)
      let resolver: Array<Promise<any>> = []
      _.forEach(this.workflows, (workflow: cx.App.Workflow) => {
        resolver = _.map(workflow.statuses, async (status: cx.App.Status) => {
          let start = await this.getStatusStartDate(this.history, status.id)
          let end = await this.getStatusEndDate(this.history, status.id)
          if (_.isEmpty(end) && !_.isEmpty(start)) {
            end = status.id === lastStatus!.id ? start : null
          }
          statusDates[status.id] = {
            start,
            end,
          }
        })
      })
      await Promise.all(resolver)
      let lastDate = statusDates[_.last(_.last(this.workflows)!.statuses)!.id]
      if (_.isEmpty(lastDate.end) && !_.isEmpty(lastDate.start)) {
        lastDate.end = lastDate.start
      }
      this.statusDates = statusDates
    } catch (error) {
      this.unknown = true
    } finally {
      this.loading = false
    }
  }
}
